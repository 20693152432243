<template>
  <div>
    <el-container> </el-container>
    <el-container> </el-container>
    <el-container>
      <el-header>
        <el-breadcrumb separator="/" width="100%">
          <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>
            <a href="/index">活动管理</a>
          </el-breadcrumb-item>
          <el-breadcrumb-item> 活动列表 </el-breadcrumb-item>
          <el-breadcrumb-item> 活动详情 </el-breadcrumb-item>
        </el-breadcrumb>
      </el-header>
      <el-main>
        <el-carousel height="350px">
          <el-carousel-item v-for="item in imgList" :key="item.name">
           
        <img :src="item.src" style="height:100%;width:100%;" alt="图片丢失了" :title="item.title" />
          </el-carousel-item>
        </el-carousel>
      </el-main>
      <el-footer> </el-footer>
    </el-container>
  </div>
</template>

<script>
  export default {
    props: [],
    components: {},
    data() {
      return {
          imgList: [
        {
          name: "lj",
          src: require("@/assets/404.png"),
          title: "这是lj.png"
        },
        {
          name: "logo",
          src: require("@/assets/404.png"),
          title: "这是logo.png"
        },
        {
          name: "bg",
          src: require("@/assets/404.png"),
          title: "这是bg.png"
        },
        {
          name: "sadmas",
          src: require("@/assets/logo.png"),
          title: "这是sadmas.png"
        }
      ],
        // 在此自动生成
        activeIndex2: "1",
        item:'',
        
      };
    },
    watch: {},
    computed: {},
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    destoried() {},
    methods: {
      request() {
        // 网络请求，可选
      },
      handleSelect: function () {
  }
    },
    fillter: {},
  };
</script>

<style scoped>
  /*  在此自动生成 */
  .el-menu-demo{}
  .small{}
</style>